import * as React from 'react';
import { Classable, HasChildren, IndicatorValue } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { ImageAsset, MarkdownContent } from '@shapeable/web';
import { Topic } from '@shapeable/gesda-types';
import { TopicLayoutBase } from './topic-layout-base';
import { TopicAnticipatoryImpactLayout } from './topic-anticipatory-impact-layout';
import { TopicAnticipationPotentialChart } from './topic-anticipation-potential-chart';
import { EntityConnectionsPanel } from './entity-connections-panel';

// -------- Types -------->

export type TopicLayoutProps = HasChildren & {
  entity: Topic;
};

// -------- Components -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const ChartStyles = breakpoints({
  base: css`
    background: #ECEDEE;
    padding: ${theme.UNIT(4)};
    margin-top: ${theme.UNIT(10)};
    margin-left: -${theme.UNIT(4)};
    margin-right: -${theme.UNIT(4)};
  `,
  desktop: css`
    padding: ${theme.UNIT(5)};
    margin-right: 0;
    margin-left: 0;
  `,
});

const AnticipatoryImpactStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(4)};
    border-top: 1px solid ${theme.COLOR('line-subtle')};
    padding-top: ${theme.UNIT(4)};
  `,
});

const ConnectionsStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(4)};
  `,
});

const My = {
  Container: styled(TopicLayoutBase)`${ContainerStyles}`,
    Chart: styled(TopicAnticipationPotentialChart)`${ChartStyles}`,
    AnticipatoryImpact: styled(TopicAnticipatoryImpactLayout)`${AnticipatoryImpactStyles}`,
    Connections: styled(EntityConnectionsPanel)`${ConnectionsStyles}`,

};

export const TopicLayout: React.FC<TopicLayoutProps> = (props) => {
  const { entity } = props;
  const { chartImage, surveyObservations, anticipatoryImpact, anticipatoryImpactImage } = entity;

  const surveyObservationsText = surveyObservations && surveyObservations.text;
  const hasAnticipatoryImpact = 
    anticipatoryImpact && anticipatoryImpact.text && 
    anticipatoryImpactImage && anticipatoryImpactImage.image && anticipatoryImpactImage.image.url;

  const deriveValue: (iv: IndicatorValue) => number = 
  (iv) => {
    return (iv.numericValue - 0.41357) * 1000 / 5.8 + 18;
  };

  return (
    <My.Container
      entity={entity}
      contentFoot={
        <My.Chart entity={entity} deriveValue={deriveValue} upperValue={60} />
      }
      asideFoot={
        <>
        {
          hasAnticipatoryImpact && 
          <My.AnticipatoryImpact entity={entity} />
        }
        <My.Connections />
        </>
      }
    />
  );
};

export default TopicLayout;

