import React from 'react';
import { Classable, HasChildren, Topic } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { BannerControls, ImageAsset, OutlineNumber, ResponsiveContainer, SectionTitle } from '@shapeable/web';

// -------- Types -------->

export type TopicBannerLayoutProps = Classable & HasChildren & { 
  entity: Topic;
};

export const TopicBannerLayoutDefaultProps: Omit<TopicBannerLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    position: relative;
    display: flex;
    flex-direction: row;
  `,
});

const BannerContainerStyles = breakpoints({
  base: css`
    position: relative;
    flex-grow: 1;
    width: 100%;
  `,
  tablet: css`
  `,
  desktop: css`
  `,
});

const BodyStyles = breakpoints({
  base: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  `,
  tablet: css`
    padding: ${theme.UNIT(13)} ${theme.UNIT(8)};
  `,
});



const TitleStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('light')};
    margin-bottom: 0;
    height: 33.333333%;
    display: flex;
    flex-shrink: 0;
    justify-content: flex-end;
    flex-direction: column;
    box-sizing: border-box;
    border-bottom: 1px solid ${theme.COLOR('light')};
    padding: ${theme.UNIT(4)} ${theme.UNIT(3)} ${theme.UNIT(2)};
  `,
  tablet: css`
    height: auto;
    padding: 0;
    border-bottom: none;
  `,
});

const ControlsStyles = breakpoints({
  base: css`
  `,
});


const HeadingStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1.0em;
    margin-top: 0;
    height: 40%;
    padding-left: ${theme.UNIT(2)};
    box-sizing: border-box;
  `,
  desktop: css`
    height: auto;
    padding-left: 0;
    padding-top: ${theme.UNIT(4)};
    margin-top: ${theme.UNIT(12)};
  `,
});

const OutlineNumberStyles = breakpoints({
  base: css`
    margin-right: ${theme.UNIT(3)};
  `,
});

const NameStyles = breakpoints({
  base: css`
    font-weight: 500;
    line-height: 1.1em;
    color: ${theme.COLOR('light')};
    font-family: ${theme.FONT('serif')};
    font-size: ${theme.FONT_SIZE(21)};
  `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(36)};
  `,
});

const ImageStyles = breakpoints({
  base: css`
    
  `,
});

const ImageContainerStyles = breakpoints({
  base: css`
    
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div`${ContainerStyles}`,
    BannerContainer: styled.div`${BannerContainerStyles}`,
      ImageContainer: styled(ResponsiveContainer)`${ImageContainerStyles}`,
        Image: styled(ImageAsset)`${ImageStyles}`,

      Body: styled.div`${BodyStyles}`,
      Title: styled(SectionTitle)`${TitleStyles}`,

      Heading: styled.h1`${HeadingStyles}`,
        OutlineNumber: styled(OutlineNumber)`${OutlineNumberStyles}`,
        Name: styled.span`${NameStyles}`,
    
    Controls: styled(BannerControls)`${ControlsStyles}`,

  };

export const TopicBannerLayout: React.FC<TopicBannerLayoutProps> = (props) => {
  const { className, entity } = props;

  const { banner, outlineNumber, name, color } = entity;

  const aspectRatio = { base: 16/9, landscape: 16/9, tablet: 21 / 9, desktop: 1398 / 386 };

  return (
    <My.Container className={className}>
      <My.BannerContainer>
        <My.ImageContainer aspectRatio={aspectRatio}>
          <My.Image entity={banner} />
        </My.ImageContainer>
        <My.Body>
          <My.Title>Emerging Topic:</My.Title>
          <My.Heading>
            <My.OutlineNumber color={color} size="banner">{outlineNumber}</My.OutlineNumber>
            <My.Name>{name}</My.Name>
          </My.Heading>
        </My.Body>
      </My.BannerContainer>
      <My.Controls />
    </My.Container>
  )
};

TopicBannerLayout.defaultProps = TopicBannerLayoutDefaultProps;