import React from 'react';
import { Classable, HasChildren, Topic } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints } from '@shapeable/theme';
import { SectionTitle, VideoImage } from '@shapeable/web';

// -------- Types -------->

export type TopicVideoLayoutProps = Classable & HasChildren & { 
  entity: Topic;
};

export const TopicVideoLayoutDefaultProps: Omit<TopicVideoLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type ColorProps = {
  _color: string;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const TitleStyles = breakpoints({
  base: css`
    
  `,
});


const ThumbnailStyles = breakpoints({
  base: css`
    position: relative;
  `,
});






// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Title: styled(SectionTitle)`${TitleStyles}`,
    Image: styled(VideoImage)`${ThumbnailStyles}`,
};

export const TopicVideoLayout: React.FC<TopicVideoLayoutProps> = (props) => {
  const { className, entity } = props;
  const { video, color } = entity;
  return (
    <My.Container className={className}>
      <My.Title>Introductory Video:</My.Title>
      <My.Image color={color && color.value} modalColor={color && color.veryDarkValue} entity={video} />
    </My.Container>
  )
};

TopicVideoLayout.defaultProps = TopicVideoLayoutDefaultProps;