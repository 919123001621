import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, ContentNode, useEntity } from '@shapeable/web';
import { TopicIndicatorChart, TopicIndicatorChartProps } from '../elements/topic-indicator-chart';
import { Topic } from '@shapeable/gesda-types';
const cls = classNames('topic-anticipatory-potential-chart');

// -------- Types -------->

export type TopicAnticipationPotentialChartProps = Classable & HasChildren & TopicIndicatorChartProps & { 
  entity?: Topic;
};

export const TopicAnticipationPotentialChartDefaultProps: Omit<TopicAnticipationPotentialChartProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const ChartStyles = breakpoints({
  base: css`
    
  `,
});

const BlurbStyles = breakpoints({
  base: css`
    
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Chart: styled(TopicIndicatorChart)`${ChartStyles}`,
    Blurb: styled(ContentNode)`${BlurbStyles}`,
};

export const TopicAnticipationPotentialChart: React.FC<TopicAnticipationPotentialChartProps> = (props) => {
  const { className, children, ...rest } = props;
  const entity = useEntity(props.entity);
  const { surveyObservations } = entity;
  
  return (
    <My.Container className={cls.name(className)}>
      <My.Chart {...rest} indicatorDataSetId="ANTICIPATION_POTENTIAL" entity={entity} />
      <My.Blurb entity={surveyObservations} />
    </My.Container>
  )
};

TopicAnticipationPotentialChart.defaultProps = TopicAnticipationPotentialChartDefaultProps;