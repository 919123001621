import * as React from 'react';
import { Classable, HasChildren } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';

import { 
  countLabel, EntityProvider, FeedEntryGridLayout, ImageAsset, MainAside, MarkdownContent,
  ParentCard, PersonListLayout, PageContentLayout, createSlot, useSlots,
} from '@shapeable/web';

import { SubTopicGridLayout } from './sub-topic-grid-layout';
import { TopicBannerLayout } from './topic-banner-layout';
import { TopicVideoLayout } from './topic-video-layout';
import { Helmet } from 'react-helmet';
import { Topic } from '@shapeable/gesda-types';
import { EntityPastYearAdvancements } from './entity-past-year-advancements';

// -------- Types -------->

export type TopicLayoutBaseProps = HasChildren & {
  entity: Topic;
  asideHead?: React.ReactNode;
  asideFoot?: React.ReactNode;
  feedEntriesHead?: React.ReactNode;
  contentHead?: React.ReactNode;
  contentFoot?: React.ReactNode;
};

// -------- Components -------->

const ContainerStyles = breakpoints({
  base: css`
    font-weight: 300;
  `,
});

const BodyStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(4)} ${theme.UNIT(3)};
  `,
  tablet: css`
    padding: ${theme.UNIT(6)} ${theme.UNIT(4)};
  `
});


const SubTopicsStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(4)} 0;
  `,
});

const HeaderStyles = breakpoints({
  base: css`
  `,
});


const PeopleStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(4)};
  `,
});

const MainAsideStyles = breakpoints({

});


const MainStyles = breakpoints({
  base: css`
  `,
});

const AsideStyles = breakpoints({
  base: css`
    
  `,
});

const ChartStyles = breakpoints({
  base: css`
    background: #ECEDEE;
    padding: ${theme.UNIT(4)};
    margin-top: ${theme.UNIT(10)};

    margin-left: -${theme.UNIT(4)};
    margin-right: -${theme.UNIT(4)};
    
  `,
  desktop: css`
    padding: ${theme.UNIT(5)};
    margin-right: 0;
    margin-left: 0;
  `,
});

const ChartImageStyles = breakpoints({
  base: css`
    max-width: 100%;
  `,
});

const SurveyObservationsStyles = breakpoints({
  base: css`
    font-weight: 300;
    padding: ${theme.UNIT(5)} 0;
  `,
});

const BannerStyles = breakpoints({
  base: css`
    
  `,
});

const FeedEntriesStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(8)};
  `,
});

const ContentStyles = breakpoints({
  base: css`
    
  `,
});

const TrendStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(4)};
  `,
});


const TopicVideoStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(4)};
  `,
});

const PastYearAdvancementsStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(10)};
  `,
});


const My = {
  Container: styled.div`${ContainerStyles}`,
    Header: styled.header`${HeaderStyles}`,
      Banner: styled(TopicBannerLayout)`${BannerStyles}`,
    
    SubTopics: styled(SubTopicGridLayout)`${SubTopicsStyles}`,
    
    Body: styled.div`${BodyStyles}`,
      
      MainAside: styled(MainAside)`${MainAsideStyles}`,
        Main: styled.div`${MainStyles}`,
          Content: styled(PageContentLayout)`${ContentStyles}`,
            PastYearAdvancements: styled(EntityPastYearAdvancements)`${PastYearAdvancementsStyles}`,
          Chart: styled.div`${ChartStyles}`,
            ChartImage: styled(ImageAsset)`${ChartImageStyles}`,
            SurveyObservations: styled(MarkdownContent)`${SurveyObservationsStyles}`,

        Aside: styled.div`${AsideStyles}`,
          People: styled(PersonListLayout)`${PeopleStyles}`,
          TopicVideo: styled(TopicVideoLayout)`${TopicVideoStyles}`,
          Trend: styled(ParentCard)`${TrendStyles}`,

      FeedEntries: styled(FeedEntryGridLayout)`${FeedEntriesStyles}`
      
};

// -------- Slots ------>

const slots = {
  AsideHead: createSlot(),
  AsideFoot: createSlot(),
  ContentHead: createSlot(),
  ContentFoot: createSlot(),
  FeedEntriesHead: createSlot(),
};

export const TopicLayoutBase: React.FC<TopicLayoutBaseProps> = (props) => {
  const { entity, children } = props;
  const { 
    intro, description, moderators = [], subTopics = [], trend, 
    banner, feedEntries = [], 
    video, color, pastYearAdvancements,
  } = entity;

  const Slots = useSlots(slots);
  
  const asideHead = props.asideHead !== undefined ? props.asideHead : <Slots.AsideHead />;
  const asideFoot = props.asideFoot !== undefined ? props.asideFoot : <Slots.AsideFoot />;
  const contentHead = props.contentHead !== undefined ? props.contentHead : <Slots.ContentHead />;
  const contentFoot = props.contentFoot !== undefined ? props.contentFoot : <Slots.ContentFoot />;
  const feedEntriesHead = props.feedEntriesHead !== undefined ? props.feedEntriesHead : <Slots.FeedEntriesHead />;

  const hasFeedEntries = !!feedEntries.length;
  const hasSubTopics = !!subTopics.length;
  const hasPeople = !!moderators.length;

  const hasTrend = !!trend;
  const hasVideo = !!video;

  return (
    <EntityProvider value={entity}>
    <My.Container>
      {
        color && 
        <Helmet>
          <meta name="theme-color" content={color && color.value} />
        </Helmet>
      }
      <My.Header>
        {
          banner && 
          <My.Banner entity={entity} />
        }
      </My.Header>
      {
        hasSubTopics &&
        <My.SubTopics items={subTopics} />
      }

      <My.Body>
        <My.MainAside
          main={
            <My.Main>
              <My.Content 
                entity={entity} 
                beforeContent={
                  <My.PastYearAdvancements entity={entity} />
                }
              />
              {
                children
              }
              {
                contentFoot
              }
            </My.Main>
          }

          aside={
            <My.Aside>
            {asideHead}
            {
              hasPeople && 
              <My.People title={countLabel(moderators.length, { one: process.env.TOPIC_MODERATOR_LABEL || 'Moderator', suffix: ':' })} items={moderators} />
            }
            {
              hasVideo && 
              <My.TopicVideo entity={entity} />
            }
            {
              hasTrend && 
              <My.Trend label="Scientific Platform:" entity={trend} />
            }
            {asideFoot}
            </My.Aside>
          }
        />

        {
          hasFeedEntries && 
          <My.FeedEntries items={feedEntries} />
        }

      </My.Body>


    </My.Container>
    </EntityProvider>
  );
};

export default TopicLayoutBase;

