import * as React from 'react';
import { graphql } from 'gatsby';
import { createGatsbyPageComponent } from '@shapeable/web';
import { TopicLayout } from '../../components/entities/topic-layout';

export default createGatsbyPageComponent('Topic', { layout: TopicLayout });

export const topicQuery = graphql`
  query TopicQuery($id: ID!) {
    platform {
      topic(id: $id) {
        id slug name __typename
        outlineNumber
        trend { id slug name __typename }
        intro { text }
        openGraph { 
          title description { plain }
          image { url thumbnails { large { url } } }
        }
        pastYearAdvancements { text }
        anticipatoryImpact { text }
        indicatorValues {
          id value numericValue year
          indicator { id name slug dataSetId }
        }
        anticipatoryImpactImage { 
          image {
            id
            url
            static {
              childImageSharp {
                gatsbyImageData(
                  breakpoints: [800, 1440, 2880]
                )
              }
            }
          }
        }
        embeds {
          citations {
            slug url name authors { id name slug } 
            authorShowsEtAl edition publication accessDate 
            startPage volume footnoteNumber year
          }
        }
        video {
          id name url
          thumbnail { id url static { childImageSharp { gatsbyImageData } } }
        }
        surveyObservations { text }
        color { id name slug value darkValue veryDarkValue }
        banner {
          id
          image {
            id
            url
            static {
              childImageSharp {
                gatsbyImageData(
                  breakpoints: [800, 1440, 2880]
                )
              }
            }
          }
        }
        trend {
          id name slug __typename
          outlineNumber
          color { id name slug value }
        }
        chartImage { 
          id
          slug
          image { 
            id url
            static { 
              childImageSharp { 
                gatsbyImageData(
                  breakpoints: [800, 1536, 1768]
                )
              } 
            }
          }
        }
        feedEntries {
          __typename _schema { label pluralLabel }
          id name slug __typename url published websiteName summary { text }
          openGraph { image { url static { childImageSharp { gatsbyImageData } } } }
        }
        description { text }
        subTopics {
          id name path outlineNumber slug __typename 
          color { id name value } 
          banner {
            id
            thumbnail { url static { childImageSharp { gatsbyImageData } } } 
          }
          indicatorValues {
            id value numericValue year
            indicator { id name slug dataSetId }
          }
        }
        globalExperts {
          __typename id name slug position
          photo { id url }
          organisation { id name slug }
        }
        moderators {
          __typename id name slug position
          photo { id url }
          organisation { id name slug }
        }
        connectedTrends {
          id name slug path outlineNumber color { value }
        }
        connectedTopics {
          id name slug path outlineNumber color { value }
        }
        connectedSubTopics {
          id name slug path outlineNumber color { value }
        }
        connectedPages {
          id name slug path type { name }
        }
      }
    }
  }
`;
