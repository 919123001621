import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { CalendarArrowIcon, classNames, useEntity, useLang } from '@shapeable/web';
import { Topic } from '@shapeable/gesda-types';
import { ContentNodeReveal } from './content-node-reveal';
const cls = classNames('entity-past-year-advancements');

// -------- Types -------->

export type EntityPastYearAdvancementsProps = Classable & HasChildren & { 
  entity?: Topic;
};

export const EntityPastYearAdvancementsDefaultProps: Omit<EntityPastYearAdvancementsProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    .shp--content-reveal__button {
      padding: ${theme.UNIT(4)};
    }
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    font-size: 0.7em;
    display: flex;
    align-items: center;
    color: ${theme.COLOR('dark')};
  `,
  tablet: css`
    padding: ${theme.UNIT(2)};
    font-size: 0.8em;
  `,
});

const IconStyles = breakpoints({
  base: css`
    margin-right: ${theme.UNIT(4)};
  `,
  tablet: css`
    margin-right: ${theme.UNIT(6)};
  `
});




// -------- Components -------->

const My = {
  Container: styled(ContentNodeReveal)<ContainerProps>`${ContainerStyles}`,
    Header: styled.span`${HeaderStyles}`,
    Icon: styled(CalendarArrowIcon)`${IconStyles}`,
};

export const EntityPastYearAdvancements: React.FC<EntityPastYearAdvancementsProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  const t = useLang();


  const [ isExpanded, setExpanded ] = React.useState<boolean>(false);
  
  const onChange = () => { setExpanded(!isExpanded); };


  const { pastYearAdvancements } = entity;
  
  if (!pastYearAdvancements || !pastYearAdvancements.text) {
    return null;
  }

  return (
    <My.Container
      isExpanded={isExpanded}
      onChange={onChange}
      header={
        <My.Header>
          <My.Icon />
          {t(isExpanded ? 'Hide advancements in the past year' : 'Show advancements in the past year')}
        </My.Header>
      }
      entity={pastYearAdvancements} 
      className={cls.name(className)} 
    />
  )
};

EntityPastYearAdvancements.defaultProps = EntityPastYearAdvancementsDefaultProps;