import React from 'react';
import { Classable, HasChildren } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { ImageAsset, MarkdownContent, SectionTitle } from '@shapeable/web';
import { Topic } from '@shapeable/gesda-types';

// -------- Types -------->

export type TopicAnticipatoryImpactLayoutProps = Classable & HasChildren & {
  entity?: Topic;
  title?: string;
}

export const TopicAnticipatoryImpactLayoutDefaultProps: Omit<TopicAnticipatoryImpactLayoutProps, 'entity'> = {
  title: 'Anticipatory Impact:',
};

// -------- Child Component Props -------->

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
  `,
});

const HeadingStyles = breakpoints({
  base: css`
    
  `,
});

const TextStyles = breakpoints({
  base: css`
    
  `,
});

const ImageStyles = breakpoints({
  base: css`
    margin: ${theme.UNIT(8)} ${theme.UNIT(4)};
    max-width: 80%;
    align-self: center;
  `,
});




// -------- Components -------->

const My = {
  Container: styled.div<any>`${ContainerStyles}`,
    Heading: styled(SectionTitle)`${HeadingStyles}`,
    Text: styled(MarkdownContent)`${TextStyles}`,
    Image: styled(ImageAsset)`${ImageStyles}`,
};

export const TopicAnticipatoryImpactLayout: React.FC<TopicAnticipatoryImpactLayoutProps> = (props) => {
  const { className, title, entity } = props;
  const { anticipatoryImpact, anticipatoryImpactImage } = entity;

  const hasText = anticipatoryImpact && !!anticipatoryImpact.text;
  const hasImage = anticipatoryImpactImage && anticipatoryImpactImage.image && anticipatoryImpactImage.image.url;

  return (
    <My.Container className={className}>
      <My.Heading>{title}</My.Heading>
      {
        hasText && <My.Text text={anticipatoryImpact.text} />
      }
      {
        hasImage && <My.Image entity={anticipatoryImpactImage} />
      }
    </My.Container>
  )
};

TopicAnticipatoryImpactLayout.defaultProps = TopicAnticipatoryImpactLayoutDefaultProps;