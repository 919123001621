import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Entity, IndicatorValue } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { CircleTrailBarChart, CircleTrailBarChartDataItem, classNames, useEntity, useLang, useLink } from '@shapeable/web';
import { Topic, SubTopic } from '@shapeable/gesda-types';
const cls = classNames('topic-indicator-chart');
import { get, flatten } from 'lodash';
import subTopic from '../../gatsby/entities/sub-topic';

// -------- Types -------->

export type TopicIndicatorChartProps = Classable & HasChildren & { 
  entity?: Topic;
  upperValue?: number;
  // which indicator should we look for (can specify either slug or ID)
  indicatorDataSetId?: string;
  indicatorSlug?: string;

  deriveValue?: (iv: IndicatorValue) => number,
};

export const TopicIndicatorChartDefaultProps: Omit<TopicIndicatorChartProps, 'entity'> = {
  deriveValue: iv => iv.numericValue,
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const ChartStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(0)};
  `,
});

const TopicLabelStyles = breakpoints({
  base: css`
    
  `,
});

const SubTopicLabelStyles = breakpoints({
  base: css`
    
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Chart: styled(CircleTrailBarChart)`${ChartStyles}`,
      TopicLabel: styled.span`${TopicLabelStyles}`,
      SubTopicLabel: styled.span`${SubTopicLabelStyles}`,
};

export const TopicIndicatorChart: React.FC<TopicIndicatorChartProps> = (props) => {
  const { className, children, indicatorSlug, indicatorDataSetId, deriveValue, upperValue } = props;
  const entity = useEntity(props.entity);
  const t = useLang();

  const { indicatorValues = [], subTopics = [], color, name, path } = entity;

  const { navigate } = useLink();

  if (!indicatorSlug && !indicatorDataSetId) {
    return null;
  }

  const isValueIncluded: (iv: IndicatorValue) => boolean = (iv) => {
    return get(iv, 'indicator.slug') === indicatorSlug || get(iv, 'indicator.dataSetId') === indicatorDataSetId;
  };

  const chartIndicatorValues: CircleTrailBarChartDataItem[] = [
    ...indicatorValues.filter(isValueIncluded).map<CircleTrailBarChartDataItem>(iv => ({ 
      ...iv, 
      value: deriveValue(iv),
      size: 'large',
      groupName: t("Emerging Topic"), 
      label: name, 
      color: color && color.value,
      labelFont: 'serif',
      onCircleClick: () => { navigate(path) },
      onLabelClick: () => { navigate(path) },
    })),
    ...subTopics.flatMap(subTopic => subTopic.indicatorValues.filter(isValueIncluded).map<CircleTrailBarChartDataItem>(iv => ({
      value: deriveValue(iv),
      size: 'default',
      groupName: t("Sub-Fields"),
      label: subTopic.name,
      color: color && color.value,
      labelFont: 'serif',
      onCircleClick: () => { navigate(subTopic.path) },
      onLabelClick: () => { navigate(subTopic.path) },
    })))
  ];
  
  return (
    <My.Container className={cls.name(className)}>
      <My.Chart 
        gridTitle={t('Anticipation Potential')}
        showTicks={false}
        heightSizeMultipliers={{
          default: 3,
          large: 2,
          small: 5
        }}
        upperValue={upperValue} 
        showValues={false} 
        showCountupValues={false} 
        backgroundColor="#ECEDEE" 
        items={chartIndicatorValues}
      />
    </My.Container>
  )
};

TopicIndicatorChart.defaultProps = TopicIndicatorChartDefaultProps;