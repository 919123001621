import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Entity, ContentNode as ContentNodeType } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, ContentRevealProps, ContentNode, ContentReveal } from '@shapeable/web';
import { GOLD_COLOR } from '../../constants';

const cls = classNames('content-node-reveal');

// -------- Types -------->

export type ContentNodeRevealProps = ContentRevealProps & { 
  entity: ContentNodeType;
};

export const ContentNodeRevealDefaultProps: Omit<ContentNodeRevealProps, 'entity' | 'header'> = {
};

// -------- Child Component Props -------->

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const ContentNodeStyles = breakpoints({
  base: css`
    font-size: 1em;
    padding: ${theme.UNIT(6)} ${theme.UNIT(6)} ${theme.UNIT(8)};
    background: ${theme.COLOR('#969696')};
    color: ${theme.COLOR('light')};

    a {
      color: #f2d1a2;
    }
  `,
});



// -------- Components -------->

const My = {
  Container: styled(ContentReveal)`${ContainerStyles}`,
    ContentNode: styled(ContentNode)`${ContentNodeStyles}`,
};

export const ContentNodeReveal: React.FC<ContentNodeRevealProps> = (props) => {
  const { className, children, entity, onChange, isExpanded } = props;
  
  return (
    <My.Container {...props} disclosureBackground="#969696" onChange={onChange} isExpanded={isExpanded} className={cls.name(className)}>
      <My.ContentNode entity={entity} />
    </My.Container>
  )
};

ContentNodeReveal.defaultProps = ContentNodeRevealDefaultProps;